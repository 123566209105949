<template>
  <div class="wrap">
    <Navbar :leftArrow="false">
      <template #left>
        <div class="left_icon" @click="setting">
          <van-icon name="setting" size="18" color='var(--colors)' />
        </div>
      </template>
      <template #right>
        <div class="right_icon" @click="service">
          <img style="height: calc(18rem / 16);" src="../../assets/tabbar/cs.svg" alt="">
        </div>
      </template>
    </Navbar>
    <div class="userInfo_bar_wrap">
      <div class="user_contentLeft">
        <div class="contentLeft_img">
          <Avatar size="55" :src="MemberInformation.userimg"
            :defaultSrc="require('@tt/assets/defaultAvatar/A6405.png')" />
        </div>
        <div class="contentLeft_text">
          <div class="username">
            {{ $t('login.welcome') }},
            {{ MemberInformation.username ? MemberInformation.username
              : $t("newAdd.noLoginUserName") }}
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content_account">
        <div class="account_item">
          <div class="label">{{ $t('accountBalance') }}</div>
          <div class="value">{{ MemberInformation.money || 0 }}</div>
        </div>
        <div class="account_item">
          <div class="label">{{ $t('todayBet') }}</div>
          <div class="value">{{ MemberInformation.today_bet || 0 }}</div>
        </div>
        <div class="account_item">
          <div class="label">{{ $t('todayIncome') }}</div>
          <div class="value">{{ MemberInformation.today_win || 0 }}</div>
        </div>
      </div>
      <div class="content_btns">
        <div class="btn_item" @click="toRecharge">
          <img :src="require('../../assets/new/user/recharge.svg')" alt="">
          <div class="text">{{ $t("My.MemberInformation.Recharge") }}</div>
        </div>
        <div class="btn_item withdraw" @click="toWithdraw" color="">
          <img :src="require('../../assets/new/user/withdraw.svg')" alt="">
          <div class="text">{{ $t("My.MemberInformation.Withdraw") }}</div>
        </div>
      </div>
      <div class="content_list">
        <div class="group" v-for="(item, index) in gridItemArr" :key="index" @click="handleClick(item)">
          <div class="left_wrap">
            <img :src="item.icon" class="img" alt="">
            <span class="label">{{ $t(item.label) }}</span>
          </div>
          <div class="right_wrap">
            <van-icon name="arrow" size="16" color="var(--light)" />
          </div>
        </div>
      </div>
      <div class="content_list border">
        <div class="group" v-for="(item, index) in gridItemArr2" :key="index" @click="handleClick(item)">
          <div class="left_wrap">
            <img :src="item.icon" class="img" alt="">
            <span class="label">{{ $t(item.label) }}</span>
          </div>
          <div class="right_wrap">
            <van-icon name="arrow" size="16" color="var(--light)" />
          </div>
        </div>
      </div>
      <div class="logout">
        <Btn block @click="logoutEvent">
          {{ MemberInformation.token ? $t("My.logout") : $t("newAdd.Login") }}
        </Btn>
      </div>
    </div>
    <setLanguage :isShow="false" ref="setLanRef" @submit="lanSubmit" />
  </div>
</template>
<script>
import { Toast, Dialog, Icon, Button as Btn, } from 'vant';
import { Avatar } from "@tt/components";
import Navbar from "@/components/JNav";
import setLanguage from "@/components/setLanguage.vue";
export default {
  components: {
    Avatar,
    [Dialog.name]: Dialog,
    Navbar,
    [Icon.name]: Icon,
    setLanguage,
    Btn
  },
  data() {
    return {
      MemberInformation: {},
      gridItemArr: [{
        label: "user.depositRecord",
        icon: require('../../assets/new/user/transfer.svg'),
        page: "bankList",
        params: { type: 'depositRecord' }
      }, {
        label: "user.withdrawRecord",
        icon: require('../../assets/new/user/transfer.svg'),
        page: "bankList",
        params: { type: 'withdrawRecord' }
      }, {
        label: "user.fundingRecords",
        icon: require('../../assets/new/user/details.svg'),
        page: "AccountData",
      }, {
        label: `user.bettingRecord`,
        icon: require('../../assets/new/user/betting.svg'),
        page: "bettingRecord",
      }],
      gridItemArr2: [{
        label: "user.personalCenter",
        icon: require('../../assets/new/user/agency.svg'),
        page: "PersonalCenter",
      }, {
        label: "user.agentCenter",
        icon: require('../../assets/new/user/agency.svg'),
        page: "agent",
      }, {
        label: "user.reportCenter",
        icon: require('../../assets/new/user/report.svg'),
        page: "ReportCenter",
      }, {
        label: "user.selectLanguage",
        icon: require('../../assets/new/user/lan.png'),
        page: "setLan",
      }],
    }
  },
  methods: {
    showSign() {
      if (this.MemberInformation?.sign) {
        Dialog.alert({
          message: this.MemberInformation.sign,
          confirmButtonText: this.$t("My.Button.Two")
        })
      }
    },
    setting() {
      this.$router.push({ name: "Setting" });
    },
    handleClick(row) {
      if (!row.page) return;
      if (row.page == 'setLan') return this.$refs.setLanRef.open();
      if (row.params) {
        this.$router.push({ name: row.page, params: row.params });
      } else {
        this.$router.push({ name: row.page });
      }
    },
    toRecharge() {
      return this.service();
      this.$router.push({ name: "NewRecharge" });
    },
    toWithdraw() {
      this.$router.push({ name: "newWithdraw" });
    },
    service() {
      this.$store.dispatch("toService");
    },
    logoutEvent() {
      let _this = this;
      if (_this.MemberInformation.token) {
        Dialog.confirm({
          message: this.$t("My.logoutEvent"),
          cancelButtonText: this.$t("My.Button.One"),
          confirmButtonText: this.$t("My.Button.Two"),
        }).then(() => {
          let pwdState = _this.$cookie.get("the-pwd-state");
          let pwdObj = _this.$cookie.get("the-pwd-obj");
          _this.$cookie.clear();
          _this.$cookie.set("the-pwd-state", pwdState);
          _this.$cookie.set("the-pwd-obj", pwdObj);
          _this.$router.push("/login");
          // window.location.reload()
        })
          .catch(() => {
            // on cancel
          });
      } else {
        this.$router.push("/login");
      }
    },
    getUserInfo() {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      this.$api.getUserInfo(
        (res) => {
          Toast.clear();
          this.MemberInformation = res
        },
        (err) => {
          Toast.clear();
        }
      );
    },
    lanSubmit(){
      this.$store.dispatch('getGameList')
    }
  },
  created() {
    this.getUserInfo();
  },
}
</script>
<style lang="less" scoped>
@Color: var(--theme);
@button-border-radius: 20px;
@wrapPadding: calc(12rem / 16);
@comRadius: 10px;
@comBG: var(--bg1);

.flex(@JC: center; @FW: nowrap; @AI: center; @FD: row) {
  display: flex;
  flex-wrap: @FW;
  flex-direction: @FD;
  justify-content: @JC;
  align-items: @AI;
}

.text-overflow(@num: 1) {
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: @num;
}

.wrap {
  min-height: calc(100vh - 50px);

  .userInfo_bar_wrap {
    overflow: hidden;
    background-color: var(--bg);
    padding: calc(20rem / 16) calc(20rem / 16) 0;

    .user_contentLeft {
      .flex(start);

      .contentLeft_text {
        margin-left: 10px;

        .username {
          font-size: calc(18rem / 16);
          color: var(--light)
        }
      }
    }
  }

  .content {
    padding: calc(15rem / 16);

    &_account {
      .flex(space-between);
      padding: calc(15rem / 16) calc(5rem / 16);
      border-radius: 5px;
      background-color: rgba(20, 35, 55, .4);

      .account_item {
        .common {
          height: calc(33rem / 16);
          display: flex;
          align-items: flex-start;
          justify-content: center;
          text-align: center;
          word-break: break-all;
          line-height: 1;
          font-size: calc(12rem / 16);
          color: #5d636e;
        }

        .label {
          .common();
          box-sizing: border-box;
          padding: 0 calc(5rem / 16)
        }

        .value {
          .common();
          height: calc(40rem / 16);
          align-items: center;
          font-size: calc(20rem / 16);
          color: var(--light);
          margin-top: calc(10rem / 16);
        }
      }
    }

    &_btns {
      margin-top: calc(20rem / 16);
      display: flex;

      .btn_item {
        flex: 1;
        border-radius: calc(10rem / 16);
        min-height: calc(44rem / 16);
        color: var(--light);
        font-size: calc(18rem / 16);
        font-weight: 600;
        .flex();
        background-image: linear-gradient(179deg, #13a2ba, #087c95);

        img {
          width: calc(20rem / 16);
          display: block;
          margin-right: calc(10rem / 16);
        }
      }

      .withdraw {
        margin-left: calc(20rem / 16);
        .btn_item();
        background-image: linear-gradient(124deg, #efdaaf, #dbb579);
      }
    }

    &_list {
      padding: calc(20rem / 16);
      margin-top: calc(15rem / 16);

      .group {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: calc(30rem / 16);

        .left_wrap {
          display: flex;
          align-items: center;

          .img {
            width: calc(20rem / 16);
            height: calc(20rem / 16);
            display: block;
          }

          .label {
            color: var(--light);
            font-size: calc(18rem / 16);
            margin-left: calc(15rem / 16);
          }
        }
      }

      .group:nth-last-child(1) {
        padding-bottom: 0;
      }
    }

    .border {
      padding-top: 30px;
      border-top: 1px solid hsla(0, 0%, 59.2%, .1283);
    }

    .logout {
      margin: @wrapPadding;
      margin-top: calc(35rem / 16);
      border-radius: 6px;
      overflow: hidden;
      /deep/ .van-button{
        background: linear-gradient(179deg, #13a2ba, #087c95) !important;
        border-color: transparent !important;
        color: var(--light) !important;
        font-size: calc(16rem / 16);
      }
    }
  }
}
</style>